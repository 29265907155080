import React from "react"
import ImageMeta from "../../components/ImageMeta"

import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

import { Transformation } from "cloudinary-react"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function OurOffice(props) {
  if (!props.post.ourOffice.hasThisSection) return <></>

  if (props.locations && props.locations === 4) {
    return (
      <section
        className="body-section"
        style={{ paddingTop: "40px", paddingBottom: "14px" }}
      >
        <div className="columns" style={{ paddingTop: "80px" }}>
          <div className="column is-2"></div>
          <div className="column">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.ourOffice.imageId}
              className="show-desktop-only"
              width="auto"
              responsive
            ></ImageMeta>
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.ourOffice.imageId}
              className="show-desktop-only-inverse"
              width="1024"
              height="1024"
              crop="fill"
              responsive
            >
              <Transformation
                height="400"
                width="auto"
                gravity="center"
                crop="fill"
                quality="auto"
                fetchFormat="auto"
              />
            </ImageMeta>
          </div>
          <div className="column is-2"></div>
        </div>
        <div
          className="columns has-text-centered"
          style={{ paddingTop: "64px", paddingBottom: "48px" }}
        >
          <div className="column is-full">
            <h3>Visit Our Offices</h3>
          </div>
        </div>
        <div className="columns">
          <div className="column"></div>
          <div
            style={{ paddingBottom: "80px" }}
            className="column is-9"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourOffice.locationCopy[0])
            )}
          ></div>
          <div className="column is-2"></div>
          <div
            style={{ paddingBottom: "80px" }}
            className="column is-9"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourOffice.locationCopy[1])
            )}
          ></div>
          <div className="column"></div>
        </div>
        <div className="columns">
          <div className="column"></div>
          <div
            style={{ paddingBottom: "80px" }}
            className="column is-9"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourOffice.locationCopy[2])
            )}
          ></div>
          <div className="column is-2"></div>
          <div
            style={{ paddingBottom: "80px" }}
            className="column is-9"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourOffice.locationCopy[3])
            )}
          ></div>
          <div className="column"></div>
        </div>
      </section>
    )
  }

  return (
    <section className="body-section our-office">
      {props.post.ourOffice.text && (
        <div className="columns">
          <div className="column is-2"></div>
          <div
            className="column mobile-col has-text-centered-tablet"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourOffice.text)
            )}
          ></div>
          <div className="column is-2"></div>
        </div>
      )}

      {props.post.ourOffice.buttons && (
        <div className="columns has-text-centered">
          <div className="column">
            <ButtonGroupMap isCentered buttons={props.post.ourOffice.buttons} />
          </div>
        </div>
      )}
      {!props.post.ourOffice.imageIdMobile ? (
        <></>
      ) : (
        <div className="columns our-office-image">
          <div className="column is-2"></div>
          <div className="column">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.ourOffice.imageId}
              className="show-desktop-only"
              width="auto"
              responsive
            ></ImageMeta>
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.ourOffice.imageIdMobile}
              className="show-desktop-only-inverse"
              width="auto"
              responsive
            >
              {/* <Transformation
                  height="400"
                  width="auto"
                  gravity="center"
                  crop="fill"
                  quality="auto"
                  fetchFormat="auto"
                /> */}
            </ImageMeta>
          </div>
          <div className="column is-2"></div>
        </div>
      )}

      {/* {props.post.ourOffice.buttons && <div className="columns has-text-centered">
        <div className="column">
          <ButtonGroupMap isCentered buttons={props.post.ourOffice.buttons} />
        </div>
      </div>} */}
    </section>
  )
}

export default OurOffice
