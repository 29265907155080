import React from "react"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()
var classNames = require("classnames")

function ReasonsWhyItem(props) {
  return (
    <div className="reasons-why-item columns" key={props.number}>
      <div className="column is-4 is-hidden-mobile">
        <span className="reason-number-circle">{props.number}</span>
      </div>

      <div className="column">
        {props.heading}
        {props.blurb}
      </div>
    </div>
  )
}

function ReasonsWhyColumn(props) {
  return <div className="reasons-why-column column">{props.children}</div>
}

function ReasonsWhy(props) {
  if (!props.post.reasonsWhySection.hasThisSection) return <></>

  var reasonsClassNames = classNames({
    "body-section reasons-why-section": true,
    "color-back": !props.invert,
    "invert-link-color": props.invert
  })

  return (
    <div className={reasonsClassNames}>
      <div className="columns">
        <div className="column is-5"></div>
        <div
          className="column"
          dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(props.post.reasonsWhySection.heading)
          )}
        ></div>
        <div className="column is-5"></div>
      </div>

      <div className="columns is-desktop">
        <div className="column is-2"></div>

        {props.post.reasonsWhySection.reasonsWhyColumn.map((column, i) => (
          <React.Fragment key={i}>
            <ReasonsWhyColumn key={i}>
              {column.map(reason => (
                <ReasonsWhyItem
                  key={reason.reason.number}
                  number={reason.reason.number}
                  heading={
                    <div className="reason-number-heading">
                      <span className="reason-number-circle mobile-in-heading">
                        {reason.reason.number}
                      </span>
                      <h5>{reason.reason.heading}</h5>
                    </div>
                  }
                  blurb={
                    <div className="reasons-why-blurb"
                      dangerouslySetInnerHTML={createHtml(
                        converter.makeHtml(reason.reason.blurb)
                      )}
                    ></div>
                  }
                />
              ))}
            </ReasonsWhyColumn>
            {i === 0 && <div className="column is-1"></div>}
          </React.Fragment>
        ))}
        <div className="column is-2"></div>
      </div>
    </div>
  )
}

export default ReasonsWhy
