import React from "react"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"

var classNames = require("classnames")

function LearnMoreCTA(props) {
  const wrapperClassNames = classNames(
    "body-sections",
    "section",
    "learn-more-section",
    {
      "color-back": props.colorBack,
    }
  )

  return (
    <section className={wrapperClassNames}>
      <div className="columns has-text-centered-tablet">
        <div className="column is-5"></div>
        <div className="column">
          <h3 style={{ marginBottom: "1.5rem" }}>
            {props.post.learnMore.heading}
          </h3>
        </div>
        <div className="column is-5"></div>
      </div>
      <div className="columns has-text-centered-tablet">
        <div className="column is-4"></div>
        <div className="column mobile-col">
          <MarkdownViewer markdown={props.post.learnMore.blurb} />
        </div>
        <div className="column is-4"></div>
      </div>

      <div className="columns">
        <div className="column is-4"></div>
        <div className="column">
          <ButtonGroup isCentered noReverse>
            <Button
              contained
              buttonText={props.post.learnMore.buttonOne.buttonText}
              href={props.post.learnMore.buttonOne.href}
            />
            {props.post.learnMore.buttonTwo.buttonText && (
              <Button
                contained
                buttonText={props.post.learnMore.buttonTwo.buttonText}
                href={props.post.learnMore.buttonTwo.href}
              />
            )}
          </ButtonGroup>
        </div>
        <div className="column is-4"></div>
      </div>
    </section>
  )
}

export default LearnMoreCTA
