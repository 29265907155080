import React from "react"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function OurMission(props) {
  return (
    <>
      {/* <div className="our-mission-stripes">
        <ImageMeta
          cloudName="nuvolum"
          publicId="OSG/DEV/line"
          width="auto"
          responsive
        />
      </div> */}

      <section
        className="body-section our-mission"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/nuvolum/image/upload/v1619659470/OSG/DEV/about-our-mission.jpg')",
        }}
      >
        <div className={`columns has-text-centered-tablet`}>
          <div className={`column is-${props.sideColumnIs}`}></div>
          <div
            className="column is-full-width"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourMission)
            )}
          ></div>
          <div className={`column is-${props.sideColumnIs}`}></div>
        </div>
      </section>

      {/* <div className="our-mission-stripes">
        <ImageMeta
          cloudName="nuvolum"
          publicId="OSG/DEV/line"
          width="auto"
          responsive
        />
      </div> */}
    </>
  )
}

export default OurMission
